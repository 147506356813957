/* Ensure styles are scoped to the mobile view */
/* .mobile-container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.mobile-container {
    /* position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden; */
    min-height: 100vh; /* Make sure the container takes at least the full viewport height */
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
    font-family: 'Montserrat', Arial, sans-serif;
}

.mb-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.mobile-container {
    position: relative;
    height: 100vh; /* Adjusted to 100% viewport height */
    width: 100%;
    overflow: hidden;
    font-family: 'Montserrat', Arial, sans-serif;
}

.mb-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%; /* Adjusted to 90% of parent container's height */
    margin-top: 20%; /* Adjusted margin for better spacing */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.55);
}
.mb-top-border-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.mb-form-content {
    width: 100%;
    max-width: 400px;
    text-align: center;
}

mb-h1 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
}

mb-form {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 700px) {
    /* Styles specific to mobile devices */
    input[type="text"],
    input[type="email"],
    input[type="date"] {
        padding: 10px;
        margin-bottom: 10px;
        border: .25px solid #ccc;
        border-radius: 5px;
    }
  }

.mb-file-upload {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
}

mb-button {
    padding: 10px;
    background-color: #00DB23;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

mb-button:hover {
    background-color: #45a049;
}

mb-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
    text-align: left;
}

.disabled {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  