html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.desktop-container {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  min-height: 100vh; /* Make sure the container takes at least the full viewport height */
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.desktop-container .container {
    width: 470px;
    height: 500px;
    margin-top: 100px;
    margin-left: 100px;
    border-radius: 23px;
    background-color: #FFFFFF;
    padding: 32px;
  }
  
  #h1-wr {
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  h3 {
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    align-items: left;
  }
  
  #input-box {
    width: 100%;
    height: 40px;
    top: 354px;
    left: 132px;
    border-radius: 5px;
    border: 0.25px solid #B7B7B7;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 400;
    padding-left: 16px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  
  }
  
  
/*   
  body {
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
   */
  .contact-email {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  #email-text {
    width: 240px;
    height: 40px;
    top: 354px;
    left: 132px;
    border-radius: 5px;
    border: 0.25px solid #B7B7B7;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 400;
    padding-left: 16px;
  }
  
  
  #contact-text {
    width: 170px;
    height: 40px;
    top: 354px;
    left: 132px;
    border-radius: 5px;
    border: 0.25px solid #B7B7B7;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 400;
    padding-left: 16px;
  }
  
  #date-text {
    width: 170px;
    height: 40px;
    top: 354px;
    left: 132px;
    border-radius: 5px;
    border: 0.25px solid #B7B7B7;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 400;
    padding-left: 16px;
    margin-right: 10px;
  }

  .parent {
    width: 100%;
  }
  
  h1, h3 {
    text-align: left;
    margin-bottom: 10px;
  }
  
  
  #file-upload {
    width: 240px;
    height: 40px;
    border-radius: 5px;
    border: 0.25px solid #B7B7B7;
    font-size: 14px;
    font-family: 'Montserrat';
    
    font-weight: 400;
    padding-left: 16px;
    background: url('https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png') center right no-repeat #e4e4e4;
    background-size: 30px;
  }
  
  #file-upload::file-selector-button {
    display: none;
  }
  
  #submit-btn {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    border-radius: 5px  ;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    background-color: #00DB23;
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .top-container {
    position: relative;
  }
  .image2 {
    position: absolute;
    top: 26px;
    left: 692px;
  }

  .disabled {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  